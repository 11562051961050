import React from "react";
import PropTypes from "prop-types";
import icon from "../images/icon.png";

const Header = props => (
  <header id="header" style={props.timeout ? { display: "none" } : {}}>
    <div className="logo">
      <img src={icon} alt="CS" />
    </div>
    <div className="content">
      <div className="inner">
        <p>
          Creasanit est une entreprise de plomberie installée au pian medoc.
          nous sommes présents sur toute la région afin de vous aider dans la
          réalisation de vos travaux de salle de bains, plomberie et chauffage.
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle("notre_activite");
            }}
          >
            Notre activité
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle("plomberie_sanitaire");
            }}
          >
            Plomberie/Sanitaire
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle("chauffage_climatisation");
            }}
          >
            Chauffage/Clim
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle("depannage");
            }}
          >
            Dépannage
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle("contact");
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
);

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool
};

export default Header;
